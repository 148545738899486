
.products-container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.page-title {
    color: #a0262e;
    font-size: 2.5em;
    text-align: left;
    margin: 0;
}
/* ffe */
.page-title-row {
    margin-top: 5%;
    margin-bottom: 0;
    width: 90%;
    margin: 5% auto 0 auto;
}