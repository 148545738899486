
.copyright-text {
    color: rgba(255, 255, 255, 0.75);
    padding: 5px 0;
}

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: black;
    color: lightgray;
    box-shadow: -2px 0px 2px 0 gray;
    z-index: 10;
    background-color: #a0262e;
}