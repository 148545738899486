

.order-delivery-label {
    color: #a0262e;
    margin-right: 2vw;
}

.order-delivery-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
}

.order-delivery-value {
    font-weight: bold;
    color: #ffc72c
}

.past-order-details-container {
    width: 75%;
    margin: 0 auto;
}

.past-order-items-container {
    width: 100%;
    height: 250px;
    overflow-y: scroll;
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 5px;
    box-shadow: 0 0 5px -1px black;
}

.past-order-items-container::-webkit-scrollbar {
    width: 20px;
    height: 100%;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
}

.past-order-items-container::-webkit-scrollbar-track {
    background-color: rgba(160, 38, 46, 0.75);
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
}

.past-order-items-container::-webkit-scrollbar-thumb {
    background-color: #a0262e;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: all 300ms;
}

.past-order-items-container::-webkit-scrollbar-thumb:hover {
    background-color: #90151d;
    box-shadow: 0 0 3px -1px gray;
}

.past-order-details-label {
    /* margin-top: 5vh; */
}