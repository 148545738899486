
.pending-orders {
    width: 80%;
    height: 30vh;
    border-radius: 5px;
    background-color: #a0262e;
    margin: 0 auto;
    overflow-y: auto;
}

.pending-orders-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 70vh;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
}

