
.half-column {
    display: flex;
    width: 80%;
    flex-direction: column;
    padding: 20px 20px;
    text-align: left;
}

.optimization-details {
    padding: 10px;
}

.optimization-details-container {
    margin: 20px 0; 
    background-color: #a0262e;
    border-radius: 5px;
    padding: 20px 10px;
    box-shadow: 0 0 3px -1px #000;
}

.optimization-details-container>p {
    margin-top: 0;
    color: #fff;
    font-size: 1.1em;
}

.optimization-details-title {
    margin: 0 0 10px 0;
    color: #fff;
    font-size: 1.80em;
}

.optimization-title, .savings-title {
    color: #a0262e;
    font-size: 2.5em;
    font-weight: 900;
    margin-top: 0;
}

.savings-table-container {
    border-radius: 5px;
}

.table-half-column {
    width: 50%;
    padding: 5px;
    display: flex;
    align-items: center;
}

.table-quarter-column {
    width: 25%;
    padding: 5px;
    display: flex;
    align-items: center;
}

.middle-column {
}

.smaller-container {
    /* height: 350px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center; */
    /* border: 1px solid black; */
}

.table-data-container {
    height: 20vh;
    overflow-y: auto;
    background-color: rgba(160, 38, 46, 0.33);
}
.table-data-container::-webkit-scrollbar {
    width: 1vw;
    background-color: #a0262e;
    border-radius: 5px;
}

.table-data-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px -1px #000;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.table-data-container::-webkit-scrollbar-thumb {
    background-color: #a0262e;
    border-radius: 5px;
    box-shadow: 0 0 3px -1px #000;
}

.table-data-row {
    width: 100%;
    background-color: #a0262e;
    margin: 1vh auto;
    border-radius: 5px;
    color: #fff;
}

.table-data-row:last-child {
    margin: 0 auto;
}

.table-headers {
    font-size: 1.1em;
}

.table-headers-row {
    background-color: #a0262e;
    color: #fff;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    box-shadow: 0 0 3px -1px #000;
    z-index: 10;
}

.table-headers-row, .table-data-row {
    display: flex;
}

.table-data-row:last-child {
    border-bottom: none;
}

.cost-optimization-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-family: sans-serif;
}

.total-savings-row {
    background-color: #a0262e;
    color: #fff;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 0 0 3px -1px #000;
}

@media screen and (max-width: 788px) {
    .cost-optimization-container {
        flex-direction: column;
        align-items: center;
    }
    .table-headers {
        font-size: 0.75em;
    }
}