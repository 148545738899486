

.order-cart-container {
    background-color: #a0262e;
    width: 100%;
    height: 75vh;
    padding: 5px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    box-shadow: -3px 0px 3px -1px gray;
    overflow: hidden;
    margin-top: 5vh;
}

.order-cart-title {
    color: white;
    text-align: left;
    font-size: 2.5em;
    margin: 0;
}

.order-checkout-button {
    width: 98%;
    margin: 1% auto;
    position: relative;
    border: 2px solid #FFC72C;
    border-radius: 5px;
    color: #FFC72C;
    padding: 10px 0;
    transition: all 300ms ease-in;
    overflow: hidden;
    z-index: 1;
    font-weight: bold;
}

.order-checkout-button:hover {
    cursor: pointer;
    color: #a0262e;
}

.order-checkout-button::after {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    background-color: #FFC72C;
    transition: all 300ms ease-in;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.order-checkout-button:hover::after {
    left: 0;
    color: #a0262e;
}

.order-items-container {
    background-color: #fff;
    width: 100%;
    height: 50%;
    border-radius: 5px;
    padding: 5px 0;
    box-shadow: inset 0 0 5px 2px gray;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.order-items-container::-webkit-scrollbar {
    width: 10px;
}

.order-items-container::-webkit-scrollbar-track {
    background-color: #a0262e;
}
.order-items-container::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
}

.no-items-text {
    color: #a0262e;
    font-weight: bold;
    font-size: 1.5em;
}

.total-row, .delivery-message-row {
    margin: 1vh 0;
}

