
.add-location-container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.85);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1000;
}

@keyframes FadeInZoomIn {
    from {
        opacity: 0;
        transform: scale(0.1);
    }
    to {
        opacity: 1.0;
        transform: scale(1.0);
    }
}

.add-location-form {
    background-color: #a0262e;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0 0 3px -1px black;
    padding: 5px 0;
}

.add-location-form-container {
    animation-name: FadeInZoomIn;
    animation-duration: 750ms;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    transition: all 750ms
}

.add-location-form-input-row {
    width: 90%;
    margin: 0 auto;
}

.close-button {
    width: 40px;
    height: 40px;
    background-color: #a0262e;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 3px -1px black;
    transition: all 500ms;
}

.close-button:hover {
    cursor: pointer;
    transform: scale(1.2);
    box-shadow: 0 0 5px -1px black;
}

.close-container-row, .add-location-form-container {
    width: 50%;
}

.save-location-button {
    width: 100%;
    background-color: #a0262e;
    margin-top: 5px;
    text-align: center;
    padding: 5px 0;
    color: #FFC72C;
    font-weight: bold;
    font-size: 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 0 3px -1px black;
    transition: all 500ms;
}

.save-location-button:hover {
    cursor: pointer;
    transform: scale(1.02);
    box-shadow: 0 0 5px -1px black;
}

.state-select-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50%;
}

.state-select {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #FFC72C;
    color: #FFC72C;
    font-weight: bold;
    font-size: 16px;
    
}

.state-select-label {
    color: #FFC72C;
    font-family: serif;
    font-size: 20px;
    font-weight: bold;
}

.two-input-row {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 788px) {
    .add-location-form-container {
        width: 80%;
    }
    .state-select-container {
        width: 50%;
    }
    .state-select {
        width: 75%;
    }

}