
.cart-action-alert-container {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 3px 1px black;
    padding: 10px;
    animation-name: FadeIn;
    animation-duration: 200ms;
    animation-iteration-count: 1;
}

.cart-action-overall-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes FadeIn {
    0% {
        opacity: 0.0
    }
    10% {
        opacity: 0.1
    }
    20% {
        opacity: 0.2
    }
    30% {
        opacity: 0.3
    }
    40% {
        opacity: 0.4
    }
    50% {
        opacity: 0.5
    }
    60% {
        opacity: 0.6
    }
    70% {
        opacity: 0.7
    }
    80% {
        opacity: 0.8
    }
    90% {
        opacity: 0.9
    }
    100% {
        opacity: 1.0
    }
}
