
.order-details-modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(255, 255, 255, 0.85);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 300ms ease-in;
    animation-name: ExpandFromMiddle;
    animation-iteration-count: 1;
    animation-duration: 300ms;
}

@keyframes ExpandFromMiddle {
    from {
        transform: scale(0.0);
        opacity: 0.0;
    }
    to {
        transform: scale(1.0);
        opacity: 1.0;
    }
}

.dismiss-modal {
    transform: scale(0.0);
    opacity: 0.0;
}