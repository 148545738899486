
.admin-home-button {
    margin-left: 5vw;
    padding: 1vh 2vw;
    border-radius: 5px;
    box-shadow: 0 0 3px -1px black;
    transition: all 400ms ease-out;
    background-color: #a0262e;
    color: #fff;
    font-weight: bold;
}

.admin-home-button:hover {
    cursor: pointer;
    transform: scale(1.075);
    box-shadow: 0 0 7px -2px black;
}

.admin-home-button:active {
    transform: scale(0.98);
    box-shadow: none;
}

.authorization-alert-container {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.33);
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.authorization-alert-dismiss-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: all 400ms ease-out;
    background-color: #a0262e;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 3px -1px black;
}

.authorization-alert-dismiss-button:hover {
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: 0 0 5px -2px black;
}

.authorization-alert-dismiss-button:active {
    transform: scale(0.9);
    box-shadow: none;
}

.authorization-alert {
    width: 50%;
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #a0262e;
    border-radius: 5px;
    box-shadow: 0 0 3px -1px black;
}

.authorization-alert-dismiss-button-row {
    width: 50%;
    margin-bottom: 1vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

ul>li {
    text-align: left;
}

.authorization-title, .authorization-message, .authorization-instruction {
    color: #fff
}

.cancel-pending-order-button, .confirm-pending-order-button {
    padding: 1vh 2vw;
    border-radius: 5px;
    box-shadow: 0 0 3px -1px black;
    transition: all 400ms ease-out;
    background-color: #a0262e;
    color: #fff;
    font-weight: bold;
}

.cancel-pending-order-button:hover, .confirm-pending-order-button:hover {
    cursor: pointer;
    transform: scale(1.075);
    box-shadow: 0 0 7px -2px black
}

.cancel-pending-order-button:active, .confirm-pending-order-button:active {
    box-shadow: none;
    transform: scale(0.98);
}

.order-details-column {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
}

.order-details-label {
    font-weight: 300;
}

.order-details-value {
    color: #a0262e;
    font-weight: bold;
    margin-left: 5px;
}

.pending-order-details-container, .peding-order-action-row {
    width: 80%;
    margin: 2vh auto;
}

.pending-order-details-container {
    display: flex;
    flex-direction: column;

}

.pending-order-details-items-row {
    width: 100%;
    height: 35vh;
    background-color: #a0262e;
    border-radius: 5px;
    overflow-y: auto;
    z-index: -1;
}

.pending-order-details-row {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.pending-order-action-row {
    width: 80%;
    height: 5vh;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.pending-order-title-row {
    width: 100%;
}