.add-location-button {
    width: 40px;
    height: 40px;
    background-color: #a0262e;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 300ms ease-in;
}

.add-location-button:hover {
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: 0 0 5px 0px black;
}

.add-location-button:active {
    transform: scale(1);
    box-shadow: none;
}

.emphasize-button {
    animation-name: GrowShrink;
    animation-iteration-count: infinite;
    animation-duration: 750ms;
    animation-timing-function: ease-in;
}