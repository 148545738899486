
.past-order-address-label {
    color: #fff;
    font-size: 0.9em;
    font-family: sans-serif;
    margin: 0 10px 0 0;
}

.past-order-address-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: baseline;
    margin-top: 2vh;
}

.past-order-address-value {
    color: #ffc72c;
    font-weight: bold;
    margin: 0;
}

.past-order-container {
    position: relative;
    width: 95%;
    margin: 3vh auto;
    display: flex;
    flex-direction: row;
    box-shadow: 0 0 4px -1px black;
    border-radius: 5px;
    padding: 1vh;
    z-index: 10;
    flex-wrap: wrap;
}

/* #ffc72c yellow */
/* #a0262e red */

.past-order-info-block {
    width: 31%;
}

.past-order-details-action-block {
    width: 6%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.past-order-details-action-block:hover {
    cursor: pointer;
}

.past-order-info-label {
    font-weight: bold;
    color: #ffffff;
}

.past-order-info-value {
    color: #ffc72c;
    font-weight: bold;
}

.past-order-details-button {
    transition: all 333ms ease-in;
}

.past-order-details-button:hover {
    transform: scale(1.05);
}

.past-order-details-chevron {
    transition: all 333ms ease-in;
}

.show-details {
    transform: rotate(180deg);
}