

.past-orders-box {
    margin-top: 0;
    background-color: #a0262e;
    width: 80%;
    padding: 10px;
    height: 25vh;
    overflow-y: scroll;
    box-shadow: 1px 1px 3px 1px gray;
    border-radius: 5px;
    overflow-x: hidden;
}

.past-orders-box::-webkit-scrollbar {
    width: 20px;
    height: 100%;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
}

.past-orders-box::-webkit-scrollbar-track {
    background-color: rgba(255,255,255, 0.15);
}

.past-orders-box::-webkit-scrollbar-thumb {
    background-color: #a0262e;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: all 300ms;
}

.past-orders-box::-webkit-scrollbar-thumb:hover {
    background-color: #90151d;
    box-shadow: 0 0 3px -1px gray;
}

.past-orders-container {
    text-align: left;
    width: 100%;
    margin: 0vh auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.white-text {
    color: #fff;
}
