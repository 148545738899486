
.add-to-staging-button {
    padding: 1vh 5vw;
    background-color: #a0262e;
    color: #fff;
    border-radius: 5px;
    transition: all 400ms ease-out;
    font-weight: bold
}

.add-to-staging-button:hover {
    cursor: pointer;
    box-shadow: 0 0 5px -1px black;
    border-radius: 10px;
}

.add-to-staging-button:active {
    box-shadow: 0 0 3px -1px black inset;
}

.add-to-staging-row {
    width: 98%;
    margin-left: 2%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.edit-item-price-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.half-column {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.item-saved-value {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.unit-label {
    color: #a0262e;
}

.unit-value {
    color: #a0262e;
    margin-left: 1vw;
    font-size: 1.25em;
    font-weight: bold;
}

.update-item-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: 40vh;
    padding: 2vh 0;
}

.update-item-value>.input-row>.input-outer>.input {
    color: #a0262e;
    border-bottom: 2px solid #a0262e;
}


.update-item-value>.input-row>.input-label-row {
    color: #a0262e;
    font-family: sans-serif;
}

.update-item-value {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}