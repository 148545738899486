
.flip-chevron {
    transform: rotate(-180deg);
}

.item-finder-container {
    position: relative;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.item-finder-input {
    border: none;
    width: 80%;
    border-bottom: 1px solid #a0262e;
    color: #a0262e;
}

.item-finder-input::placeholder {
    color: #a0262e;
    opacity: 0.5;
}

.item-finder-input-container {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.item-finder-label {
    margin-bottom: 1%;
    color: #a0262e;
}

.item-finder-label-row {
    width: 50%;
    display: flex;
    justify-content: flex-start;
}

.suggestions-container {
    position: absolute;
    top: 100%;
    display: block;
    background-color: #a0262e;
    color: #fff;
    overflow-y: auto;
    box-shadow: 0 0 3px -1px gray;
    max-height: 30vh;
    width: 80%;
    animation-name: FadeIn;
    animation-duration: 300ms;
    animation-iteration-count: 1;
    border-radius: 5px;
    z-index: 1000;
}

.suggestions-indicator {
    transition: all 300ms;
    color: #a0262e;
}

.suggestions-indicator:hover {
    cursor: pointer;
}

.suggestions-indicator-container {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.update-item-item-finder {
    width: 100%;
}

@keyframes FadeIn {
    0% {
        opacity: 0.0;
    }
    12% {
        opacity: 0.125;
    }
    25% {
        opacity: 0.25;
    }
    37% {
        opacity: 0.375
    }
    50% {
        opacity: 0.5;
    }
    62% {
        opacity: 0.625;
    }
    75% {
        opacity: 0.75;
    }
    87% {
        opacity: 0.875;
    }
    100% {
        opacity: 1;
    }
}