

.mobile-nav-button {
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: all 300ms;
}

.mobile-nav-button:hover {
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: 0 0 3px -1px gray;
}