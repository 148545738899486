
.pending-order-container {
    width: 90%;
    margin: 2vh auto;
    display: flex;
    flex-direction: row;
    background-color: rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 3px -1px black;
    border-radius: 5px;
    padding: 1vw;
    transition: all 400ms ease-out
}

.pending-order-container:hover {
    cursor: pointer;
    transform: scale(1.025);
    box-shadow: 0 0 7px -2px black;
}

.pending-order-section {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.pending-order-text {
    color: #fff;
    text-align: left;
}