
.delete-staged-item-icon {
    transition: all 400ms ease-out;
}

.staged-item-action-button:hover {
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 0 0 5px 2px #fff inset, 0 0 5px -2px black;
}

.staged-item-action-button {
    transition: all 400ms ease-out;
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 50%;
    box-shadow: 0 0 3px 1px #fff inset, 0 0 3px -1px black;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.label-row, .value-row {
    width: 100%;
}

.staged-item-info-block {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
}

.staged-item-info-row:first-child>.staged-item-info-block {
    width: 100%;
    margin-bottom: 5px;
}

.staged-item-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.staged-item-label {
    margin: 0;
}

.staged-item-info-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}