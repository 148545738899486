
.location-action-row {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.location-container {
    width: 95%;
    border-radius: 5px;
    background-color: #a0262e;
    box-shadow: 0 0 5px -1px black;
    margin: 2vh auto;
    display: flex;
    flex-direction: row;
    padding: 2vh 1vw;
}

/* sdf */

.location-info-label {
    color: #fff;
}

.location-info-value {
    color: #ffc72f;
}


.location-info-container {
    width: 25%;
}

.location-info-row {
    width: 75%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.remove-location-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 3px 1px white inset;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 400ms ease-out;
}

.remove-location-button:hover {
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: 0 0 5px 2px white inset ;
}

@media screen and (max-width: 788px) {
    .location-info-value {
        font-size: 0.75em;;
    }
}