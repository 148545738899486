

.contact-modal-container {
    position: absolute;
    margin-top: 75px;
    background-color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 0 3px -1px black;
    transition: all 300ms;
    animation-name: slide-down;
    animation-duration: 400ms;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
}

@keyframes slide-down {
    from {
        margin-top: 0px;
        opacity: 0;
    }
    to {
        margin-top: 75px;
        opacity: 1.0
    }
}

.modal-link {
    color: #a0262e;
    text-decoration: none;
    padding: 10px 80px;
    font-weight: bold;
}

.modal-row {
    transition: all 300ms;
}

.modal-row:first-child {
    border-bottom: 2px solid #a0262e;
}

.modal-row:hover {
    box-shadow: 0 0 3px -1px black;
}