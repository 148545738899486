
.about-us-info-container {
    width: 100%;
    text-align: left;
    padding-left: 3vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.about-us-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.about-us-section-container {
    display: flex;
    width: 90%;
    margin: 3% auto;
    border-radius: 5px;
}

.about-us-section-title {
    color: #fff;
    text-align: left;
    font-weight: 900;
    font-size: 1.5em;
}

p {
    color: #fff;
    font-weight: 500;
}

.content-container {
    width: 75%;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.column-flex {
    flex-direction: column;;
}

.contact-us-button {
    border-radius: 5px;
    background-color: white;
    width: 50%;
    padding: 15px 0;
    transition: all 300ms;
    color: #a0262e;
    font-weight: bold;
}

.contact-us-button:hover {
    cursor: pointer;
    box-shadow: 0 0 5px -1px black;
}

.family-text {
    font-family: sans-serif;
    font-size: 18px;
    margin-bottom: 10%;
}

.flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.half-flex-container {
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: #a0262e;
    transition: all 400ms ease-out;
}

.half-flex-container:nth-child(odd) {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.half-flex-container:nth-child(even) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.headline {
    font-size: 2em;
    font-family: sans-serif;
    font-weight: 700;
    letter-spacing: 0px;
}

.left-aligned-list {
    text-align: left;
}

.left-aligned-list>li {
    font-size: 14px;
    color: #fff;
    font-weight: 900;
}

.logo-img {
    width: 100%;
}

.modal-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.red-container {
    background-color: #a0262e;
    padding: 20px 0;
}

.section-title {
    color: #a0262e;
    font-size: 2em;
    font-family: sans-serif;
    font-weight: 900;
}

.section-title-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 800px) {
    .half-flex-container {
        width: 100%;
        border-radius: 5px;
        margin: 2vh 0;
    }
    .about-us-image {
        border-radius: 5px;
    }
}

@media only screen and (max-width: 580px) {
    .about-us-section-container {
        flex-direction: column;
    }
}