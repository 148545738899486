
.location-selection-container {
    width: 100%;
}

.locations-selector-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
}

.error {
    color: #f00;
    opacity: 0.5;
    font-size: 0.75em;
    margin-left: 2vw;
}