

.back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1vw;
    background-color: #a0262e;
    padding: 1vh 2vw;
    border-radius: 5px;
    transition: all 300ms ease-in;
}

.back-button:hover {
    box-shadow: 0 0 3px 1px black;
    cursor: pointer;
    transform: scale(1.02);
}

.back-button:active {
    transform: scale(0.98);
    box-shadow: none
}

.back-button-text {
    color: #fff;
}

.order-confirmation-container {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.order-message {
    color: #a0262e;
    font-size: 1.25em;
    text-align: left;
}

.order-title {
    font-size: 3em;
    color: #a0262e;
}

.message-row, .title-row {
    width: 50%;
}

.message-row {
    margin-bottom: 2vh;
}

.title-row {

}

@media screen and (max-width: 788px) {
    .order-message {
        font-size: 1em;
    }
    .order-title {
        font-size: 1.75em;
    }
}
