
.order-form-container {
    width: 100%;
}

.add-to-order-button {
    padding: 10px 25px;
    border: 2px solid #a0262e;
    border-radius: 5px;
    color: #a0262e;
    font-weight: bold;
    position: relative;
    transition: all 400ms ease-in;
    z-index: 5;
    overflow: hidden;
}

.add-to-order-button::after {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #a0262e;
    transition: all 300ms ease-in;
    z-index: -1;
}

.add-to-order-button:hover {
    color: white;
    cursor: pointer;
}

.add-to-order-button:hover::after {
    left: 0;
}

.error {
    color: #f00;
    font-weight: 900;
    opacity: 0.5;
}

.order-form-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 5%;
}

.order-form-row:last-child {
    justify-content: flex-start;
    padding-left: 12.5%;
}

.item-price-container, .total-price-container {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.item-price-details-container {
    width: 50%;
}

.price-details {
    margin-top: 0;
    color: #a0262e;
}

.price-label {
    margin-bottom: 1%;
    color: #a0262e;
}

.units-or-case-selection-container {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.units-selection-box, .case-selection-box {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #a0262e;
    border-radius: 5px;
    transition: all 400ms ease-out;
    font-size: 1em;
    font-weight: bold
}

.units-selection-box:hover, .case-selection-box:hover {
    cursor: pointer;
    background-color: #eee;
}

.units-selection-container, .case-selection-container {
    width: 40%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #a0262e;
}

.or-descriptive-container {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #a0262e;
}

.case-details-container {
    color: #a0262e;
}