
.order-online-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.seventy-width-container {
    width: 65%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.thirty-width-container {
    width: 35%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 788px) {
    .order-online-container {
        flex-direction: column;
    }
    .seventy-width-container, .thirty-width-container {
        width: 100%;
    }
}