
.account-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.information-section-label {
    margin-bottom: 0;
}

.logout-button {
    border-radius: 5px;
    background-color: #ffc72c;
    padding: 5px 10px;
    color: #a0262e;
    font-weight: bold;
    transition: all 300ms;
    margin-top: 3vh;
}

.logout-button:hover {
    cursor: pointer;
    box-shadow: 0 0 3px -1px black;
    transform: scale(1.05);
}

.logout-button:active {
    box-shadow: none;
    transform: scale(0.95);
}

.logout-row {
    width: 80%;
    margin: 2% auto 0 auto;
    display: flex;
    justify-content: flex-end;
}

.user-information {
    margin-top: 1%;
    font-weight: 300;
}

.user-information-container {
    background-color: #a0262e;
    width: 80%;
    margin: 0 auto;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 3px 1px gray;
    color: white;
}

.user-information-row {
    text-align: left;
}

.user-information-section-label-row {
    border-bottom: 2px solid #ffc72c;
}