.nav-header-row {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    box-shadow: 0px 1px 3px -1px black;
    background-color: #a0262e;
    padding: 5px 0;
    z-index: 1000;
}

.nav-link {
    text-decoration: none;
    margin: 0 10px;
    color: #fff;
    font-weight: bold;
    font-size: 0.9em;
    transition: all 300ms;
    border: none;
    padding-bottom: 5px;
}


.active-link {
    border-bottom: 1px solid #fff;
}

.icon-color-change {
    color: #ffc72c;
    flex: 1;
}

.icon-color-change:hover {
    cursor: pointer;
    color: #a0262e;
}

.nav-link:hover {
    color: #ffc72c;
}

.nav-links-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 60%;
}

.nav-title-container {
    width: 40%;
    display: flex;
    align-items: center;
}

.nav-title {
    text-align: left;
    margin-left: 5%;
    font-family: sans-serif;
    font-weight: 400;
}

.nav-title-link {
    color: #fff;
    text-decoration: none;
}

.mobile-nav-header-img {
    width: 50px;
    border-radius: 50%;
    animation-name: FadeIn;
    animation-duration: 1250ms;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
}

@keyframes FadeIn {
    from {
        opacity: 0%;
    }
    to {
        opacity: 100%;
    }
}

.mobile-nav-link {
    text-decoration: none;
    color: #fff;
    padding: 5px;
    transition: all 300ms;
}

.mobile-nav-link:hover {
    color: #ffc72c;
    cursor: pointer;
    box-shadow: 0 0 3px -1px black;
}

.mobile-nav-link:not(:last-child) {
    border-bottom: 1px solid #fff;
}

.mobile-nav-links {
    position: absolute;
    display: flex;
    flex-direction: column;
    text-align: left;
    top: 100%;
    background-color: #a0262e;
    border-radius: 5px;
    box-shadow: 0 0 3px -1px black;
    z-index: 100;
}

.mobile-nav-links-container {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10%;
    z-index: 1000;
}

.user-icon {
    color: #fff;
}

.user-icon:hover {
    cursor: pointer;
    color: #ffc72c;
}

.vertical-separator {
    margin-right: 20px;
    margin-left: 10px;
    color: #fff;
}

@media screen and (max-width: 788px) {
    .user-icon {
        color: #fff;
    }
    .user-icon:hover {
        color: #ffc72c;
    }
}