
.account-verification-details-container {
    width: 75vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

.account-verification-message {
    color: #a0262e;
    font-size: 1.25em;
}

.account-verification-title {
    font-size: 2.5em;
    font-family: sans-serif;
}

.account-verification-title-row {
    height: 40%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}