/* gold: #ffc72c */
/* crimson: #a0262e */
.order-again-button {
    display: flex;
    padding: 1vh 2vw;
    border: 2px solid #a0262e;
    border-radius: 5px;
    color: #a0262e;
    position: relative;
    z-index: 11;
    overflow: hidden;
    transition: all 300ms;
    font-weight: bold;
}

.order-again-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 102%;
    height: 100%;
    background-color: #a0262e;
    transition: all 300ms;
    transform: translateX(-100%);
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.order-again-button:hover::before {
    transform: translateX(0);
}

.order-again-button:active::before {
    transform: translateX(-25%);
}

.order-again-button:hover {
    cursor: pointer;
    color: #ffc72c;
}

.order-again-button-row {
    width: 75%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1vh 0;
}