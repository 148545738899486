
.layout-container {
    margin: 7vh 0;
    overflow-y: auto;
    overflow-x: hidden;
}

@media screen and (max-width: 528px) {
    .layout-container {
        margin: 9.5vh 0;
    }
}