.section-title {
    color: #a0262e;
    text-align: left;
    margin-bottom: 0;
}

.section-title-row {
    width: 80%;
    margin: 0 auto;
    border: #a0262e;
    display: inline-block;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
}