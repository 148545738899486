

.spinning-loader {
    animation-name: Spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
}


.spinning-loader-row {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes Spin {
    from {
        transform: rotate(0deg) scale(1);
    }
    to {
        transform: rotate(360deg) scale(1.15);
    }
}