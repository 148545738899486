
/* ecwe */
.item-info-block {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2vh;
}

.item-info-block-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2vh;
}

.item-info-block-row:first-child {
    margin-top: 2vh;
}

.item-info-label {
    color: #ffc72c;
    font-weight: 300;
    font-size: 1em;
}

.item-info-value {
    color: #fff;
    font-weight: 900;
    font-size: 1.05em;
}

.past-order-item-container {
    position: relative;
    background-color: #a0262e;
    width: 50%;
    box-shadow: 0 0 3px -1px black;
    margin: 3vh auto 1vh auto;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: -1; 
}

.order-item-edit-action-row {
    width: 50%;
    margin: 0 auto 1vh auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.order-item-edit-button {
    width: 36px;
    height: 36px;
    border-radius: 5px;
    box-shadow: 0 0 3px -1px black;
    background-color: #a0262e;
    transition: all 400ms ease-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.order-item-edit-button:hover {
    cursor: pointer;
    box-shadow: 0 0 5px -2px black;
    transform: scale(1.05);
}

.order-item-edit-button:active {
    transform: scale(0.95);
    box-shadow: none;
}

.editing-buttons-container {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.editing-button {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 0 0 3px -1px black;
    transition: all 400ms ease-out;
    color: #fff;
    font-weight: bold;
    padding: 1vh 0;
}

.editing-button:hover {
    cursor: pointer;
    box-shadow: 0 0 5px -2px black;
    transform: scale(1.05);
}

.editing-button:active {
    transform: scale(0.95);
    box-shadow: none;
}

.cancel-editing-button {
    background-color: #ffc72c;
    color: #a0262e;
}

.confirm-editing-button {
    background-color: #a0262e;
    margin-left: 10%;
}

.item-error-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.item-processing-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: rgba(0,0,0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}