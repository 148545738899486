
.action-button {
    width: 50%;
    padding: 15px 0;
    border-radius: 5px;
    transition: all 400ms;
    font-weight: bold;
    transition: all 400ms ease-out;
    box-shadow: 0 0 3px -1px black;
}

.action-button:hover {
    cursor: pointer;
    box-shadow: 0 0 7px -2px black;
    transform: scale(1.075);
}

.action-button:active {
    box-shadow: none;
    transform: scale(0.98);
}

.action-button-row {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 20px 0;
}

.action-login-button {
    background-color: #ffc72c;
    color: #fff;
}

.action-signup-button {
    background-color: #a0262e;
    color: #fff;
}

.user-auth-back-button {
    background-color: #a0262e;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1vh;
    border-radius: 5px;
    margin-bottom: 1vh;
    transition: all 400ms ease-out;
    box-shadow: 0 0 3px -1px black;
}

.user-auth-back-button:hover {
    cursor: pointer;
    box-shadow: 0 0 7px -2px black;
    transform: scale(1.05);
}

.user-auth-back-button:active {
    box-shadow: none;
    transform: scale(0.98);
}

.back-button-row {
    width: 75%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
}

.column-flex-container {
    display: flex;
    flex-direction: column;
    min-width: 75%;
    box-shadow: 1px 1px 3px -1px gray;
    align-items: center;
    justify-content: center;
}

.forgot-password-button {
    background-color: #FFC72C;
    padding: 5px;
    border-radius: 5px;
    color: #a0262e;
    transition: all 300ms;
    margin-right: 10px;
}

.forgot-password-button-row {
    font-size: 12px;
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
}

.forgot-password-button:hover {
    cursor: pointer;
    transform: scale(1.075);
    box-shadow: 0 0 3px -1px black;
}

.or-row {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffc72c;
}

.send-button {
    position: relative;
    border:  3px solid #a0262e;
    color: #a0262e;
    width: 100%;
    padding: 1% 0;
    border-radius: 5px;
    font-weight: bold;
    transition: all 400ms;
    background-color: transparent;
    z-index: 1;
    overflow: hidden;
}

.send-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 102%;
    height: 100%;
    background-color: #a0262e;
    transition: all 300ms;
    transform: translateX(-100%);
    z-index: -1;
}

.send-button:hover::before {
    transform: translateX(0);
}


.send-button:hover {
    cursor: pointer;
    color: #ffc72c;
}

.send-button-row {
    margin-top: 1%;
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20vh;
}