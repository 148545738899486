.admin-login-container {
    width: 100vw;
    height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form {
    background-color: #a0262e;
    width: 90%;
    margin: 0 auto;
    border-radius: 5px;
    box-shadow: 0 0 5px -1px black;
    padding: 1vh 0;
}

.form-row {
    width: 95%;
    margin: 0 auto;
}

.form-login-button {
    position: relative;
    border:  3px solid #a0262e;
    color: #a0262e;
    width: 90%;
    padding: 1% 0;
    border-radius: 5px;
    font-weight: bold;
    transition: all 400ms;
    background-color: transparent;
    z-index: 1;
    overflow: hidden;
    margin: 2vh auto;
}

.form-login-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 102%;
    height: 100%;
    background-color: #a0262e;
    transition: all 300ms;
    transform: translateX(-100%);
    z-index: -1;
}

.form-login-button:hover::before {
    transform: translateX(0);
}


.form-login-button:hover {
    cursor: pointer;
    color: #ffc72c;
}