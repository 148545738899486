
.action-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2vh 0;
}

.close-btn-container {
    position: absolute;
    background-color: #a0262e;
    top: 5%;
    right: 5%;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 3px -1px black;
    transition: all 400ms ease-out;
}

.close-btn-container:hover {
    cursor: pointer;
    transform: scale(1.075);
    box-shadow: 0 0 7px -1px black;
}

.close-btn-container:active {
    transform: scale(0.98);
    box-shadow: none;
}

.user-auth-alert-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    top: 0;
    right: 0;
    z-index: 100;
    transition: all 400ms ease-out;
    animation-name: DropDown;
    animation-iteration-count: 1;
    animation-duration: 400ms;
    animation-timing-function: ease-out;
}

.message {
    text-align: left;
    margin-bottom: 5vh;
    color: #a0262e;
    font-weight: 900;
}

.sub-message {
    font-weight: 500;
}

@keyframes DropDown {
    0% {
        top: -100%;
    }
    100% {
        top: 0
    }
}

@keyframes DropDownMobile {
    0% {
        top: -100%
    }
    100% {
        top: 50%;
    }
}

@media screen and (max-width: 788px) {
    .user-auth-alert-container {
        width: 100%;
        top: 50%;
        right: 0;
        animation-name: DropDownMobile;
        animation-iteration-count: 1;
        animation-duration: 400ms;
        animation-timing-function: ease-out;
    }
}