
.column-flex-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 40%;
    box-shadow: 1px 1px 3px -1px gray;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: #a0262e
}

.description-text {
    color: #ffc72c;
}