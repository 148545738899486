
.account-verifying-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 90vh;
}

.account-verifying-title {
    color: #a0262e;
    font-size: 3em;
}


.verifying-loader {
    animation-name: Spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
}

.stop-loader {
    animation-iteration-count: 0;
}

.verifying-loader-row {
    
}

@keyframes Spin {
    40% {
        transform: rotate(40deg) scale(1.2);
    }
    100% {
        transform: rotate(320deg) scale(0.95);
    }
}